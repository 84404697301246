.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

#butterfly {
  position: absolute;
  width: 70px;
  height: 50px;
  left: 10%;
  display: block;
  mix-blend-mode: multiply;
  border: none;
  z-index: 99;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
